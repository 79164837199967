import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";

const Partners = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(activeMenuAction('Partners'));
	}, [])

	return (
		<>
			{/* <div className="container-fluid bg-primary py-5 bg-hero mb-5">
        <div className="container py-5">
            <div className="row justify-content-start">
                <div className="col-lg-8 text-center text-lg-start">
                    <h1 className="display-1 text-white mb-md-4">Testimonials</h1>
                    <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
                    <a href="" className="btn btn-secondary py-md-3 px-md-5">About Us</a>
                </div>
            </div>
        </div>
    </div> */}

			<div className="container-fluid about pt-5">
				<div className="container">
					<div className="row gx-5">
						<div className="col-lg-12 pb-5">
							<div className="mb-3 pb-2">
								<h1 className="display-5">Industrial Revolution Takes Place When We Grow Together.</h1>
							</div>
							<p className="mb-4">The global agriculture industry is rapidly growing towards a smart industrial revolution of digitalization, and we are in the growth business! Since the past two decades, Khalihan has remained committed to offering best-in-industry technology for agriculture management. Khalihan has created a brand that offers an agricultural digital integration platform for each and every stakeholder that is a part of the agricultural supply chain.</p>
							<div className="row gx-5 gy-4">
								<div className="col-sm-12">
									<p className="mb-0">Our partnership program is for agencies and agricultural stakeholders, where we nurture our partner community with world-class farm management solutions, services, and resources, so that they can maximize returns on their investments. Khalihan offers various partner programs, for various stakeholders perceiving different objectives, to strengthen their brand in the industry they are aiming to move up.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-12 pb-5">
							<div className="mb-3 pb-2">
								<h1 className="display-5">Partner with Khalihan</h1>
							</div>
							<p className="mb-4">The global agriculture industry is rapidly growing towards a smart industrial revolution of digitalization, and we are in the growth business! Since the past two decades, Khalihan has remained committed to offering best-in-industry technology for agriculture management. Khalihan has created a brand that offers an agricultural digital integration platform for each and every stakeholder that is a part of the agricultural supply chain.</p>
							<div className="row gx-5 gy-4">
								<div className="col-sm-12">
									<p className="mb-0">Our partnership program is for agencies and agricultural stakeholders, where we nurture our partner community with world-class farm management solutions, services, and resources, so that they can maximize returns on their investments. Khalihan offers various partner programs, for various stakeholders perceiving different objectives, to strengthen their brand in the industry they are aiming to move up.</p>
								</div>
								<div className=" col-3"> <a href="" className="btn btn-secondary py-md-3 px-md-5">Join the Network</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid py-5">
				<div className="container">

					<div className="row g-0">
						<div className="col-lg-6">
							<div className=" h-100 p-5">
								<form>
									<div className="row g-3">
										<div className="col-lg-12 pb-5">
											<div className="mb-3 pb-2">
												<h1 className="display-5">Khalihan Strategic Alliance Partner</h1>
											</div>
											<p className="mb-4">The global agriculture industry is rapidly growing towards a smart industrial revolution of digitalization, and we are in the growth business! Since the past two decades, Khalihan has remained committed to offering best-in-industry technology for agriculture management. Khalihan has created a brand that offers an agricultural digital integration platform for each and every stakeholder that is a part of the agricultural supply chain.</p>
											<div className="row gx-5 gy-4">
												<div className="col-sm-12">
													<p className="mb-0">Our partnership program is for agencies and agricultural stakeholders, where we nurture our partner community with world-class farm management solutions, services, and resources, so that they can maximize returns on their investments. Khalihan offers various partner programs, for various stakeholders perceiving different objectives, to strengthen their brand in the industry they are aiming to move up.</p>
												</div>
												<div className=" col-3"> <a href="" className="btn btn-secondary py-md-3 px-md-5">Join the Network</a></div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6">
							<div className=" h-100 p-5">
								<form>
									<div className="row g-3">
										<div className="col-lg-12 pb-5">
											<div className="mb-3 pb-2">
												<h1 className="display-5">Khalihan R&D
													Partner</h1>
											</div>

											<div className="row gx-5 gy-4">
												<div className="col-sm-12">
													<p className="mb-0">Khalihan offerings are an outcome of rigorous research & development (R&D) efforts put in by highly skilled IT and agriculture specialists.
														We, at Khalihan, firmly believe that quality can only be enhanced through strong R&D. Consequently, we form a strong partnership around the globe with eminent Research & Development establishments working in the field of agriculture, horticulture, fisheries, climate change, information technologies, electronics, Internet of Things, and Big Data. We have experience of working on joint R&D projects which involved scientists, statisticians, modelers and designers. These projects normally lead to generation of joint Intellectual Property (IP) and commercializing it.</p>
												</div>
												<div className=" col-3"> <a href="" className="btn btn-secondary py-md-3 px-md-5">Join the Network</a></div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="container-fluid bg-primary facts py-5 mb-5">
        <div className="container py-5">
            <div className="row gx-5 gy-4">
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-star fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Our Experience</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-users fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Farm Specialist</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-check fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Complete Project</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-mug-hot fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Happy Clients</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
			{/* <div className="container-fluid about pt-5">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-6 mb-5 mb-lg-0">
                    <div className="d-flex h-100 border border-5 border-primary border-bottom-0 pt-4">
                        <img className="img-fluid mt-auto mx-auto" src="img/about.png"/>
                    </div>
                </div>
                <div className="col-lg-6 pb-5">
                    <div className="mb-3 pb-2">
                        <h6 className="text-primary text-uppercase">About Us</h6>
                        <h1 className="display-5">We Produce Organic Food For Your Family</h1>
                    </div>
                    <p className="mb-4">Tempor erat elitr at rebum at at clita. Diam dolor diam ipsum et tempor sit. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor eirmod magna dolore erat amet magna</p>
                    <div className="row gx-5 gy-4">
                        <div className="col-sm-6">
                            <i className="fa fa-seedling display-1 text-secondary"></i>
                            <h4>100% Organic</h4>
                            <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita nonumy dolor magna dolor vero</p>
                        </div>
                        <div className="col-sm-6">
                            <i className="fa fa-award display-1 text-secondary"></i>
                            <h4>Award Winning</h4>
                            <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita nonumy dolor magna dolor vero</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid about pt-5">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-6 mb-5 mb-lg-0">
                    <div className="d-flex h-100 border border-5 border-primary border-bottom-0 pt-4">
                        <img className="img-fluid mt-auto mx-auto" />
                    </div>
                </div>
                <div className="col-lg-6 pb-5">
                    <div className="mb-3 pb-2">
                        <h6 className="text-primary text-uppercase">About Us</h6>
                        <h1 className="display-5">We Produce Organic Food For Your Family</h1>
                    </div>
                    <p className="mb-4">Tempor erat elitr at rebum at at clita. Diam dolor diam ipsum et tempor sit. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor eirmod magna dolore erat amet magna</p>
                    <div className="row gx-5 gy-4">
                        <div className="col-sm-6">
                            <i className="fa fa-seedling display-1 text-secondary"></i>
                            <h4>100% Organic</h4>
                            <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita nonumy dolor magna dolor vero</p>
                        </div>
                        <div className="col-sm-6">
                            <i className="fa fa-award display-1 text-secondary"></i>
                            <h4>Award Winning</h4>
                            <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita nonumy dolor magna dolor vero</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
		</>
	);
}

export default Partners;
