import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import crosolImage from "../../../src/assests/img/carousel-1.jpg" ;
import crosolImage2 from "../../../src/assests/img/carousel-2.jpg" ;

const HomePage = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(activeMenuAction('HomePage'));
    },[])

  return (
    <>
    {/* ------------ slider ------- */}
    <div className="container-fluid p-0">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src={crosolImage} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                            <h3 className="text-white">Harvest</h3>
                            <h1 className="display-1 text-white mb-md-4">PRODUCTIVITY, PREDICTABILITY & PROFITABILITY</h1>
                            <p className="slide-text-subtitle text-white mb-md-4">To Optimize Food Systems</p>
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know How</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage2} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                            <h3 className="text-white">3000 Ha of Cassava Farm in</h3>
                            <h1 className="display-1 text-white mb-md-4">Nigeria Selects Khalihan</h1>
                            <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p>
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Read the Case Study</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                            <h1 className="text-white">Track and Manage Your Entire Infrastructure Over Maps</h1>
                            {/* <h1 className="display-1 text-white mb-md-4">Nigeria Selects Khalihan</h1>
                            <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know More</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage2} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">Experience the Power of a Groundbreaking Agriculture ERP Platform</h1>
                            <h3 className="text-white">Single Sign-On Experience for Businesses</h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know More</a>
                        </div>
                    </div>
                </div>
           
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage2} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">Experience the Power of a Groundbreaking Agriculture ERP Platform</h1>
                            <h3 className="text-white">Single Sign-On Experience for Businesses</h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know More</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">Excellent Production, Planning Control and Multi-site Inventory Analytics</h1>
                            <h3 className="text-white">For Medium to Large-sized Plantations</h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know More</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">Techpowering Agriculture</h1>
                            <h3 className="text-white">Industry-leading Farm Management Software Trusted by Global Agribusinesses </h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Know More</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">Leading Vegetable Grower Chooses Khalihan to Streamline Labour Management</h1>
                            <h3 className="text-white">With AI-driven System that Uses Face Recognition Technology</h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Read the Case Study</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={crosolImage2} alt="Image"/>
                    <div className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                        <div className="text-start p-5 mx-wid-900px" >
                        <h1 className="display-1 text-white mb-md-4">4 Cutting-edge ERP Trends that  <br/> are Ruling the Agriculture Sector</h1>
                            <h3 className="text-white">Khalihan's Game-changing Project for Smallholder Vegetable Farmers</h3>
                            {/* <p className="slide-text-subtitle text-white mb-md-4">to Manage their Plantation Operations</p> */}
                            <a href="" className="btn btn-secondary py-md-3 px-md-5">Read Blog</a>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
{/* ----------- slider end------------- */}
    <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-5">
            <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-carrot display-1 text-primary mb-3"></i>
                        <h4>Plantation and Farm</h4>
                        <p className="mb-0">Simplifying management of plantation and farm activities and encouraging data-driven, smart decision making.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-carrot display-1 text-primary mb-3"></i>
                        <h4>Contract Farming</h4>
                        <p className="mb-0">Establishing buyer-farmer agreements and coordinating, promoting production and marketing in agriculture.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-apple-alt display-1 text-primary mb-3"></i>
                        <h4>Government Institution</h4>
                        <p className="mb-0">Joint Agri-IT programs to spread agricultural knowhow among farmers at grass root level.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-dog display-1 text-primary mb-3"></i>
                        <h4>Export & Pack House</h4>
                        <p className="mb-0">Driving higher profit margins with precise export documentation and optimized pack house operations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-tractor display-1 text-primary mb-3"></i>
                        <h4>FPO/ Co-operative</h4>
                        <p className="mb-0">Helping FPOs in empowering farmers to develop new market opportunities and strengthen their position in the marketplace.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-seedling display-1 text-primary mb-3"></i>
                        <h4>Greenhouse / Hydroponics / floriculture</h4>
                        <p className="mb-0">Planning and budgeting activities for effective management of plant nurseries as well as hydroponics and floriculture operations.</p>
                    </div>
                </div>
                {/* ------- */}
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className=" fa fa-tractor display-1 text-primary mb-3"></i>
                        <h4>Biotech / Tissue Culture</h4>
                        <p className="mb-0">Simplifying the process of growing and managing plants on a large scale in a laboratory.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-dog display-1 text-primary mb-3"></i>
                        <h4>Research & Development</h4>
                        <p className="mb-0">Collaborating with agricultural scientists to carry out effective agricultural research and development programs.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-seedling display-1 text-primary mb-3"></i>
                        <h4>Agribusiness Company</h4>
                        <p className="mb-0">Enabling the connection of businesses with a large number of farmers over scattered geographies.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-dog display-1 text-primary mb-3"></i>
                        <h4>Organic Farming</h4>
                        <p className="mb-0">Ensuring easy compliances and better business management to drive better profit margins.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-tractor display-1 text-primary mb-3"></i>
                        <h4>NGOs</h4>
                        <p className="mb-0">Supporting NGOs in managing capacity building, watershed development, farm productivity improvement, etc.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-seedling display-1 text-primary mb-3"></i>
                        <h4>Agriculture Consulting</h4>
                        <p className="mb-0">Enhancing coordination by bringing agricultural consultants and farmers over a single digital platform.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

 
   <div className="container-fluid bg-primary py-5 bg-hero mb-5">
        <div className="container py-5">
            <div className="row justify-content-start">
                <div className="col-lg-8 text-center text-lg-start">
                    <h1 className="display-1 text-white mb-md-4">About Us</h1>
                    <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
                    <a href="" className="btn btn-secondary py-md-3 px-md-5">About Us</a>
                </div>
            </div>
        </div>
    </div>
 
    <div className="container-fluid about pt-5">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-12 pb-5">
                    <div className="mb-3 pb-2">
                        <h6 className="text-primary text-uppercase">About Us</h6>
                        <h1 className="display-5">The Changing Face of Digital Agriculture</h1>
                    </div>
                    <p className="mb-4">Khalihan has emerged as the trailblazer in farm business management, since the agriculture industry started seeing a sea change with adoption of technologies. We are serving agribusinesses in 25+ countries since 2001</p>
                    <div className="row gx-5 gy-4">
                        <div className="col-sm-12">

                            <p className="mb-0">Khalihan is committed to boosting overall efficiency of digital farming while making ‘sustainable agriculture’ a new normal. Two of the United Nations’ Sustainable Development Goals (SDGs) – Zero Hunger and Climate change, are our guiding light for driving the holistic growth of the agriculture industry.<br/>
                            Being a proud member of GLOBALG.A.P and Sustainable Rice Platform, Khalihan has always strived to raise the bar for food safety and sustainability.</p>
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-primary facts py-5 mb-5">
        <div className="container py-5">
            <div className="row gx-5 gy-4">
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-star fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Our Experience</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-users fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Farm Specialist</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-check fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Complete Project</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-mug-hot fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Happy Clients</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    </>
  );
}

export default HomePage;
