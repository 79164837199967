import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import blogImg from "../../../src/assests/img/blog-1.jpg" ;
import featuareImg from "../../../src/assests/img/feature.png" ;
import blog from "../../../src/assests/img/blog-1.jpg" ;
import blog2 from "../../../src/assests/img/blog-2.jpg" ;
import blog3 from "../../../src/assests/img/blog-3.jpg" ;
import userImg from "../../../src/assests/img/user.jpg" ;


const BlogGrid = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
      dispatch(activeMenuAction('BlogGrid'));
  },[])

  return (
    <>
 
 <div className="container-fluid bg-primary py-5 bg-hero mb-5">
        <div className="container py-5">
            <div className="row justify-content-start">
                <div className="col-lg-8 text-center text-lg-start">
                    <h1 className="display-1 text-white mb-md-4">Blog Grid</h1>
                    <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
                    <a href="" className="btn btn-secondary py-md-3 px-md-5">Blog Grid</a>
                </div>
            </div>
        </div>
    </div>


    <div className="container py-5">
        <div className="row g-5">
      
            <div className="col-lg-8">
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog2} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog3} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
          
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
          
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog2} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
          
                    <div className="col-md-6">
                        <div className="blog-item position-relative overflow-hidden">
                            <img className="img-fluid" src={blog} alt=""/>
                            <a className="blog-overlay" href="">
                                <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                                <span className="text-white fw-bold">Jan 01, 2050</span>
                            </a>
                        </div>
                    </div>
          
          
          
                 
              
                    <div className="col-12">
                        <nav aria-label="Page navigation">
                          <ul className="pagination pagination-lg justify-content-center m-0">
                            <li className="page-item disabled">
                              <a className="page-link rounded-0" href="#" aria-label="Previous">
                                <span aria-hidden="true"><i className="bi bi-arrow-left"></i></span>
                              </a>
                            </li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                              <a className="page-link rounded-0" href="#" aria-label="Next">
                                <span aria-hidden="true"><i className="bi bi-arrow-right"></i></span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                    </div>
                </div>
            </div>
           
            <div className="col-lg-4">
      
                <div className="mb-5">
                    <div className="input-group">
                        <input type="text" className="form-control p-3" placeholder="Keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            
                <div className="mb-5">
                    <h2 className="mb-4">Categories</h2>
                    <div className="d-flex flex-column justify-content-start bg-primary p-4">
                        <a className="fs-5 fw-bold text-white mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Design</a>
                        <a className="fs-5 fw-bold text-white mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                        <a className="fs-5 fw-bold text-white mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                        <a className="fs-5 fw-bold text-white mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Keyword Research</a>
                        <a className="fs-5 fw-bold text-white" href="#"><i className="bi bi-arrow-right me-2"></i>Email Marketing</a>
                    </div>
                </div>
           
                <div className="mb-5">
                    <h2 className="mb-4">Recent Post</h2>
                    <div className="bg-primary p-4">
                        <div className="d-flex overflow-hidden mb-3">
                            <img className="img-fluid flex-shrink-0 w-75px" src={blog2}  alt=""/>
                            <a href="" className="d-flex align-items-center bg-white text-dark fs-5 fw-bold px-3 mb-0">Lorem ipsum dolor sit amet elit
                            </a>
                        </div>
                        
                    </div>
                </div>
       
                <div className="mb-5">
                    <img src={blog} alt="" className="img-fluid rounded"/>
                </div>
           
                <div className="mb-5">
                    <h2 className="mb-4">Tag Cloud</h2>
                    <div className="d-flex flex-wrap m-n1">
                        <a href="" className="btn btn-primary m-1">Design</a>
                        <a href="" className="btn btn-primary m-1">Development</a>
                        <a href="" className="btn btn-primary m-1">Marketing</a>
                        <a href="" className="btn btn-primary m-1">SEO</a>
                        <a href="" className="btn btn-primary m-1">Writing</a>
                        <a href="" className="btn btn-primary m-1">Consulting</a>
                        <a href="" className="btn btn-primary m-1">Design</a>
                        <a href="" className="btn btn-primary m-1">Development</a>
                        <a href="" className="btn btn-primary m-1">Marketing</a>
                        <a href="" className="btn btn-primary m-1">SEO</a>
                        <a href="" className="btn btn-primary m-1">Writing</a>
                        <a href="" className="btn btn-primary m-1">Consulting</a>
                    </div>
                </div>
      
                <div>
                    <h2 className="mb-4">Plain Text</h2>
                    <div className="bg-primary text-center text-white p-30px">
                        <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
                        <a href="" className="btn btn-secondary py-2 px-4">Read More</a>
                    </div>
                </div>
  
            </div>

        </div>
    </div>

    </>
  );
}

export default BlogGrid;
