import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import testiImg from "../../../src/assests/img/testimonial-1.jpg";
import testiImg2 from "../../../src/assests/img/testimonial-2.jpg";
import $ from "jquery";
import jQuery from "jquery";





const Testimonials = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(activeMenuAction('Testimonials'));


		// $(".testimonial-carousel").owlCarousel({
		//     autoplay: true,
		//     smartSpeed: 1000,
		//     items: 1,
		//     dots: false,
		//     loop: true,
		//     nav : true,
		//     navText : [
		//         '<i class="bi bi-arrow-left"></i>',
		//         '<i class="bi bi-arrow-right"></i>'
		//     ]
		// });

	}, [])


	return (
		<>
			<div className="container-fluid bg-primary py-5 bg-hero mb-5">
				<div className="container py-5">
					<div className="row justify-content-start">
						<div className="col-lg-8 text-center text-lg-start">
							<h1 className="display-1 text-white mb-md-4">Testimonial</h1>
							<a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
							<a href="" className="btn btn-secondary py-md-3 px-md-5">Testimonial</a>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="container-fluid bg-testimonial py-5 testimonial-margin " >
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-7">
							<div className="owl-carousel testimonial-carousel p-5">
								<div className="testimonial-item text-center text-white">
									<img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={testiImg} alt="" />
									<p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
									<hr className="mx-auto w-25" />
									<h4 className="text-white mb-0">Client Name</h4>
								</div>
								<div className="testimonial-item text-center text-white">
									<img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={testiImg2} alt="" />
									<p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
									<hr className="mx-auto w-25" />
									<h4 className="text-white mb-0">Client Name</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}



			<div className="container-fluid  bg-light py-5" >
				<div className="container py-5">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div id="carouselExamplesDark" class="carousel slide">
								<div class="carousel-inner">
									<div class="carousel-item active">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"Managing a huge farmland of 40000 acres with diverse crops like rubber, paddy and sugarcane crops is not an easy task. Many standard ERPs which we evaluated were very rigid to fit to our agribusiness processes. Khalihan with its multi-crop, multi-location features provided to be the best fit for our complex operations. Our on-farm users were professionally trained by Khalihan team with their unique and user-friendly training program. A powerful software platform coupled with an unmatched implementation strategy helped us Go Live in record time. We look forward to a continued association with Khalihan." </p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary   mb-0">DGM</h5>
											<p className="fs-5  mb-0">A Plantation Company</p>
											<p className="fs-5  mb-0">Bangkok, Thailand. </p>
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"Khalihan team has impressive business requirement mapping skills. During our relationship, they displayed the highest levels of technical and implementation expertise. Khalihan delivered a top notch product that has gone well beyond our expectations. And that's why they have the reason to be proud of their world class "Khalihan" product and team. Their professional expertise for lOT and Data analytics has helped us to take our project to next level” 
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Head - Information Technology  
											</h5>
											<p className="fs-5  mb-0">AzerSun Holdings</p>
											<p className="fs-5  mb-0">Baku, Azerbaijan. </p>
											
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">“We are extremely happy and satisfied to have found Khalihan to manage our largest fruit farming operations in Turkey. Khalihan technically proved to be very robust software platform which complements our vision to lead this industry. They have displayed great level of domain expertise, flexibility, agility and scalability. Their team is proactive, open and is highly specialized in their approach towards integrating Khalihan with our legacy financial software system. I can say it was a perfect decision to select Khalihan as a technology partner." 
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">CTO
											</h5>
											<p className="fs-5  mb-0">Largest Fruit Farming and Processing Company </p>
											<p className="fs-5  mb-0">Istambul, Turkey. </p>
										</div>  
										  </div>
										<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"Our business requires us to be agile as we are new age, high tech Plant Tissue Culture Company. Our challenge is timely supply of high quality disease free plants to our customers. Our relationship with Khalihan has helped us rise to this challenge. As Khalihan functional experts had in-depth knowledge of tissue culture business processes, they were successful in deploying best industry practices through Khalihan software platform. Khalihan support team has proactively solved issues impacting our daily operations and handled the continuous releases without much downtime."  
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Chief Production Incharge 
							
											</h5>
											<p className="fs-5  mb-0">Plant Tissue Culture laboratory </p>
											<p className="fs-5  mb-0">Durban, South Africa. 
							 </p>
										</div>
										</div>
										<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"We are excited to partner with Khalihan to streamline our nursery and agroforestry operations for 32000+ farmers and 41000+ hectares of land. Khalihan as a scalable software platform on cloud enabled us to achieve timely and quality supply of plants to farmers. Data analytics and reporting modules provided key management users with ready to use data insights. There has been excellent technical support provided by the Khalihan team. We are impressed by their commitment, responsiveness and tenacity.” 
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Production 
											</h5>
											<p className="fs-5  mb-0">Largest Agroforestry Plant Producer Company  </p>
											<p className="fs-5  mb-0">New Delhi, India. </p>
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">“Our farming capabilities required a single ERP platform that could be integrated with various other existing software used across our multi location businesses. With the successful implementation of Khalihan at our farms we expect to have better data transparency and tree-wise traceability,  enabling us to streamline our plantation management operations with the utmost efficiency. Today, through Khalihan, we also expect significant success in the areas of inventory management and Labor management, where data transparency plays a substantial role.” 
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Pauline Le Gouze 
											</h5>
											<p className="fs-5  mb-0">VP Operations</p>
											<p className="fs-5  mb-0">Simply Natural Farms, Panama. 
							 </p>
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"Working with Khalihan is definitely the best ever experience. Khalihan team has an absolute ‘can do' attitude which led us to achieve our objective of bringing the entire 'Farm to Fork' business operations on a single digital platform. We are particularly impressed with the post implementation technical support provided by them in peak fruit season" 
							
							
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Chaitanya Rajwade 
							
							
											</h5>
											<p className="fs-5  mb-0">Head - Agribusiness </p>
											<p className="fs-5  mb-0">
Mahindra Agri Solutions Pvt. Ltd., India. 
							 
							</p>
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"We engaged with Khalihan to enable our managers to better estimate the crop yield at our farms located pan India. We found this platform extremely beneficial mainly due to its user-friendly interface, which enabled all our employees to get data visibility across the entire supply chain."  
							
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Mudasir Tak
							
											</h5>
											<p className="fs-5  mb-0">Global head -Fresh Produce</p>
											<p className="fs-5  mb-0">
Phoenix Global DMCC, UAE. 
							
							 </p>
										</div>
									</div>
									<div class="carousel-item">
										<div className="testimonial-item text-center text-black">
											<p className="fs-5">"While maintaining the quality of fresh fruits was the critical challenge for us, it was also becoming very tedious for us to carry out inspections manually. Khalihan helped us manage our planning and execution activities by improving efficiency of our human resources for manpower planning, which has immensely helped us maximize per hour business productivity."  
							
											</p>
											<hr className="mx-auto w-25" />
											<h5 className="fs-5 text-secondary mb-0">Punya Nanayakkara,  
							
											</h5>
											<p className="fs-5  mb-0">Director</p>
											<p className="fs-5  mb-0">Nelna Mango, Sri Lanka. 
							</p>
										</div>
									</div>
									
									
								</div>
								<button class="carousel-control-prev new-testimonail-margin-left" type="button" data-bs-target="#carouselExamplesDark" data-bs-slide="prev">
									<span class="carousel-control-prev-icon " aria-hidden="true"></span>
									<span class="visually-hidden">Previous</span>
								</button>
								<button class="carousel-control-next new-testimonail-margin-right" type="button" data-bs-target="#carouselExamplesDark" data-bs-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="visually-hidden">Next</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>






			{/* <div className="container-fluid bg-testimonial py-5 testimonial-margin testimonial-margin" >
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="owl-carousel testimonial-carousel p-5">
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={crosolImage1} alt=""/>
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25"/>
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={crosolImage1} alt=""/>
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25"/>
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
		</>
	);
}

export default Testimonials;
