import React from "react";
import { Route, Routes } from "react-router-dom";
import SidebarLayout from "../Common/SidebarLayout.js";
import ContactUs from "../Home/ConatctUs.js";
import HomePage from "../Home/Home.js";
import Partners from "../Partners/Partners.js";
import Testimonials from "../Testimonials/Testimonials.js";
import About from "../About/About.js";
import Service from "../Serviece/Service.js";
import Features from "../Features/Features.js";
import BlogDetail from "../BlogDetail/BlogDetail.js";
import BlogGrid from "../BlogGrid/BlogGrid.js";
import TheTeam from "../TheTeam/TheTeam.js";


const FormRoute = () => {
  return (
    <Routes>
    <Route element={<SidebarLayout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/plat-form" element={<Service />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/features" element={<Features />} />
      <Route path="/testimonials" element={<Testimonials />}/>
      <Route path="/blog-detail" element={<BlogDetail />}/>
      <Route path="/blog-grid" element={<BlogGrid />}/>
      <Route path="/team" element={<TheTeam />}/>
      </Route>
    </Routes>
  );
};

export default FormRoute;
