import React from "react";
import { Toaster } from 'react-hot-toast'; // Imported toast globally
import { BrowserRouter as Router } from "react-router-dom";
import FormRoute from "./components/Routes/FormRoute";
import Footer from "../src/components/Common/Footer.js";
import "./App.css";
import "./assests/custom.css";


function App() {
  
  return (
    <Router>
      <div className="page">
        <FormRoute />
        <Toaster />
      </div>
      <Footer />
    </Router>
  );
}

export default App;
