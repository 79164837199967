const initialState = {
    ActiveMenu: "dashboard",
  };
  
  const activeMenuReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ACTIVEMENU':
        if(!action.payload)
        {
          return initialState
        }
        else{
          return{
            ...state,
            ActiveMenu : action.payload
          }
        }
        default:
          return state;
    }
  };
  
  export default activeMenuReducer;