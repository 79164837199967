import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import testiImg from "../../../src/assests/img/testimonial-1.jpg";
import testiImg2 from "../../../src/assests/img/testimonial-2.jpg";
import $ from "jquery";
import jQuery from "jquery";
import team from "../../../src/assests/img/team-1.jpg" ;
import team2 from "../../../src/assests/img/team-2.jpg" ;
import team3 from "../../../src/assests/img/team-3.jpg" ;

const TheTeam = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(activeMenuAction('Testimonials'));


		// $(".testimonial-carousel").owlCarousel({
		//     autoplay: true,
		//     smartSpeed: 1000,
		//     items: 1,
		//     dots: false,
		//     loop: true,
		//     nav : true,
		//     navText : [
		//         '<i className="bi bi-arrow-left"></i>',
		//         '<i className="bi bi-arrow-right"></i>'
		//     ]
		// });

	}, [])
	return (
		<>
	    <div className="container-fluid bg-primary py-5 bg-hero mb-5">
        <div className="container py-5">
            <div className="row justify-content-start">
                <div className="col-lg-8 text-center text-lg-start">
                    <h1 className="display-1 text-white mb-md-4">The Team</h1>
                    <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
                    <a href="" className="btn btn-secondary py-md-3 px-md-5">The Team</a>
                </div>
            </div>
        </div>
    </div>



    <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5 mx-500">
                <h6 className="text-primary text-uppercase">The Team</h6>
                <h1 className="display-5">We Are Professional Organic Farmers</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team2} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team3} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
			





     
		</>
	);
}

export default TheTeam;
