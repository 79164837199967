import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../Reducers';

const store = configureStore({
    reducer: { rootReducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
},
    window.__REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION_()
);

export default store;